/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
//@import "~@ngx-share/button/themes/default/default-theme";
//@import '~ngx-sharebuttons/themes/default/default-theme';

@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

//@import '~ngx-sharebuttons/themes/material/material-dark-theme';
//@import '~ngx-sharebuttons/themes/modern/modern-light-theme';

//@import '~@syncfusion/ej2-base/styles/material.css';
//@import '~@syncfusion/ej2-buttons/styles/material.css';
//@import '~@syncfusion/ej2-popups/styles/material.css';
//@import '~@syncfusion/ej2-splitbuttons/styles/material.css';
//@import '~@syncfusion/ej2-navigations/styles/material.css';
//@import '~@syncfusion/ej2-angular-lists/styles/material.css';
//@import '~@syncfusion/ej2-angular-schedule/styles/material.css';
//@import '~@syncfusion/ej2-angular-popups/styles/material.css';
//@import '~@syncfusion/ej2-material-theme/styles/material.css';
//@import '~@syncfusion/ej2-calendars/styles/material.css';
//@import '~@syncfusion/ej2-dropdowns/styles/material.css';
//@import '~@syncfusion/ej2-inputs/styles/material.css';
//@import '~@syncfusion/ej2-lists/styles/material.css';

@import '~leaflet/dist/leaflet.css';

.wideModal {
  --min-height: 90%;
  --min-width: 90%;
}

.fullModal {
  --min-height: 98%;
  --min-width: 98%;
}

.smallModal {
  --max-height: 70%;
  --max-width: 90%;
}

img.blur {
  filter: blur(5px);
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
}

.mat-horizontal-content-container {
  padding: 0 !important;
}

.mat-horizontal-stepper-header {
  padding: 0 10px !important;
  height: 25px !important;
}

.mat-horizontal-stepper-header-container {
  // height: 30px;
}

.mat-horizontal-stepper-header .mat-step-icon {
  width: 17px;
  height: 17px;
  font-size: 12px;
}

.mat-vertical-content-container {
  margin-left: 0 !important;
}

.mat-vertical-content {
  padding: 0 !important;
}

.customListView {
  border: 1px solid !important;
  margin-left: 5px;
  width: 98% !important;
}

.mat-vertical-stepper-header {
  height: 5px !important;
}

//.mat-step-header {
//  height: 40px !important;
//}

.searchbar-input.sc-ion-searchbar-md {
  line-height: 20px;
}

.searchbar-search-icon.sc-ion-searchbar-md {
  top: 5px;
}

div .item-inner {
  padding: 0;
}

ion-item .item-native {
  min-height: 30px !important;
}

.sb-button {
  margin: 0.2em !important;
}

.schedule-cell-dimension-mobile.e-schedule
  .e-vertical-view
  .e-time-cells-wrap
  table
  td,
.schedule-cell-dimension.e-schedule .e-vertical-view .e-work-cells {
  //height: 20px;
}

.schedule-cell-dimension.e-schedule .e-month-view .e-work-cells {
  //height: 20px;
}

.e-schedule .e-vertical-view .e-time-cells-wrap table td,
.e-schedule .e-vertical-view .e-work-cells {
  //height: 20px !important;
}

// This is to set color of events
.custom-class.e-schedule
  .e-vertical-view
  .e-all-day-appointment-wrapper
  .e-appointment,
.custom-class.e-schedule .e-vertical-view .e-day-wrapper .e-appointment,
.custom-class.e-schedule .e-month-view .e-appointment {
  //background: grey !important;
  width: 50% !important;
}

// This is to set color of events
.custom-class-2.e-schedule
  .e-vertical-view
  .e-all-day-appointment-wrapper
  .e-appointment,
.custom-class-2.e-schedule .e-vertical-view .e-day-wrapper .e-appointment,
.custom-class-2.e-schedule .e-month-view .e-appointment {
  //background: grey !important;
  width: 100% !important;
}

.e-schedule
  .e-vertical-view
  .e-day-wrapper
  .e-appointment
  .e-appointment-details {
  height: 100% !important;
  padding: 0 !important;
}

// disable all-day events
.e-schedule .e-date-header-wrap .e-schedule-table thead {
  display: none;
}

// Tooltip
.e-tooltip-wrap {
  opacity: 1 !important;
}

international-phone-number input {
  background-color: white !important;
  border: none !important;
  outline: none;
}

international-phone-number input:focus-visible {
  outline: none;
}
